/*

1. Global
2. Tailwind-like
3. Customize
4. Media Query

*/

/* /////////////////////////////////////////////
  1. Global
///////////////////////////////////////////// */
html {
  display: flex;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'PingFang SC', 'Helvetica Neue', STHeiti,
    'Microsoft Yahei', Tahoma, Simsun, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  -webkit-font-smoothing: antialiased;
  vertical-align: baseline;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  min-width: 1170px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
}

img {
  display: block;
}

a {
  text-decoration: none;
}

::selection {
  background-color: #013bd4;
  color: #fff;
}

/* /////////////////////////////////////////////
  2. Tailwind-like
///////////////////////////////////////////// */

.rela {
  position: relative;
}

.abs {
  position: absolute;
}

.inline {
  display: inline-block;
}

.fullw {
  width: 100%;
}
.fullh {
  height: 100%;
}

.flex {
  display: flex;
}
.flexY {
  display: flex;
  flex-direction: column;
}
.grow1 {
  flex-grow: 1;
}

.ai-ct {
  align-items: center;
}
.jc-sa {
  justify-content: space-around;
}
.jc-sb {
  justify-content: space-between;
}
.jc-ct {
  justify-content: center;
}

.cfff {
  color: #fff;
}
.c234 {
  color: #283044;
}

.bg-black {
  background-color: #283044;
}
.bg-blue {
  background-color: #013bd4;
}

.lh18 {
  line-height: 18px;
}
.lh20 {
  line-height: 20px;
}
.lh24 {
  line-height: 24px;
}
.lh32 {
  line-height: 32px;
}
.lh44 {
  line-height: 44px;
}
.lh56 {
  line-height: 56px;
}
.fs12 {
  font-size: 12px;
}
.fs14 {
  font-size: 14px;
}
.fs18 {
  font-size: 18px;
}
.fs24 {
  font-size: 24px;
}
.fs32 {
  font-size: 32px;
}
.fs40 {
  font-size: 40px;
}
.fw4 {
  font-weight: 400;
}
.fw5 {
  font-weight: 500;
}
.ta-ct {
  text-align: center;
}

.mt12 {
  margin-top: 12px;
}
.mt16 {
  margin-top: 16px;
}
.mt30 {
  margin-top: 30px;
}
.mt46 {
  margin-top: 46px;
}
.mt64 {
  margin-top: 64px;
}
.mt104 {
  margin-top: 104px;
}
.mt120 {
  margin-top: 120px;
}
.ml56 {
  margin-left: 56px;
}
.ml32 {
  margin-left: 32px;
}
.ml20 {
  margin-left: 20px;
}
.ph70 {
  padding-left: 70px;
  padding-right: 70px;
}

.op85 {
  opacity: 0.85;
  transition: opacity 0.37s;
}
.op85:hover {
  opacity: 1;
}
.op65 {
  opacity: 0.65;
}

.poin {
  cursor: pointer;
}

/* /////////////////////////////////////////////
  3. Customize
///////////////////////////////////////////// */

#bg-blue {
  padding: 30px 136px 0;
}

#nav {
  max-width: 1170px;
  min-width: 768px;
}

#first-page {
  max-width: 1150px;
  min-width: 996px;
}

#icon-bcz {
  width: 101px;
  height: 23px;
}

@keyframes shake {
  0%,
  to {
    transform: translateY(0);
    box-shadow: rgba(0, 0, 0, 0) 0 2px 8px;
  }
  50% {
    transform: translateY(-5px);
    box-shadow: rgba(0, 0, 0, 0.12) 0 2px 8px;
  }
}
.button,
button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  outline: 0;
  border: 0;
}
.button span,
button span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

#qr-weixin {
  position: absolute;
  top: 15px;
  right: -8px;
  width: 186px;
  height: 210px;
  opacity: 0;
  pointer-events: none;
  transition: transform 0.37s, opacity 0.37s;
  z-index: 2;
}

#qr-weixin._show {
  opacity: 1;
  transform: translateY(15px);
}
#nav-campusCooperation {
  margin-right: 20px;
}
#anim {
  width: 538px;
  height: 577px;
  overflow: hidden;
}
#screenshot-left {
  position: absolute;
  width: 82px;
  height: 478px;
  bottom: -50px;
  left: 43px;
  opacity: 0;
  transition: transform 0.37s 0.06s, opacity 0.37s 0.06s;
  box-shadow: 0 6px 26px rgba(0, 0, 0, 0.12);
}
#screenshot-left._show {
  transform: translate(-30px, -50px);
  opacity: 1;
}

#screenshot-iphone {
  left: 82px;
  bottom: -40px;
  width: 374px;
  height: 577px;
  z-index: 2;
  transition: transform 0.37s;
  box-shadow: 0 6px 26px rgba(0, 0, 0, 0.12);
}

#screenshot-iphone._show {
  transform: translate(0, -40px);
  opacity: 1;
}

#screenshot-right {
  position: absolute;
  bottom: -50px;
  right: 34px;
  height: 400px;
  width: 88px;
  opacity: 0;
  transition: transform 0.37s 0.06s, opacity 0.37s 0.06s;
  box-shadow: 0 6px 26px rgba(0, 0, 0, 0.12);
}
#screenshot-right._show {
  transform: translate(30px, -50px);
  opacity: 1;
}

#illu-slogen {
  margin-top: 80px;
  width: 564px;
  height: 300px;
  margin-bottom: 200px;
}

#btn-apple,
#btn-android {
  width: 172px;
  height: 68px;
  background: linear-gradient(135deg, #2f2f2f 0%, #000 100%);
  box-shadow: 0 4px 12px 0px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  bottom: 64px;
  padding: 0 20px;
  transition: all 0.3s;
}

#btn-apple::after,
#btn-android::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  background: linear-gradient(135deg, #404040 0%, #000 100%);
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.12);
  opacity: 0;
  transition: opacity 0.37s;
  z-index: 1;
}

#btn-apple {
  left: 96px;
}

#btn-android {
  left: 300px;
}

#logo-apple,
#logo-android {
  width: 100%;
  pointer-events: none;
  z-index: 2;
}

#company-banner {
  height: 252px;
  background: #1e1f23;
  border-radius: 4px;
  max-width: 1170px;
  min-width: 375px;
  margin: 40px auto 0;
  overflow-x: hidden;
}
#government-banner {
  height: 195px;
  width: 1170px;
  min-width: 375px;
  margin: 40px auto 0;
  border-radius: 0;
  position: relative;
}
#government-banner img {
  height: 100%;
  width: 100%;
}
#cooporation-banner {
  height: 252px;
  background: #1e1f23;
  border-radius: 4px;
  max-width: 1170px;
  min-width: 375px;
  margin: 0 auto;
  overflow-x: hidden;
}
#company-mask {
  right: 0;
  top: 0;
}
#company-slogan {
  height: 173px;
  left: 47px;
  top: 38px;
}
#company-button,
#cooporation-button {
  position: absolute;
  right: 47px;
  bottom: 41px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 60px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.qr-ctnr_apple,
.qr-ctnr_android {
  width: 196px;
  height: 216px;
  bottom: 70px;
  left: -11px;
  opacity: 0;
  transform: translateY(32px);
  pointer-events: none;
  border-radius: 2px;
}

.qr-ctnr_apple._show,
.qr-ctnr_android._show {
  opacity: 1;
  transform: translateY(0);
}

#illu-1,
#illu-2,
#illu-3 {
  width: 250px;
  height: 250px;
}
.width-10 {
  padding-left: 70px;
  padding-right: 70px;
}
.section {
  max-width: 1440px;
  padding: 145px 135px 95px;
  margin: 0 auto;
  overflow-x: hidden;
}
.section > * {
  width: 100%;
  min-width: 825px;
}
.video-ctnr {
  width: 450px;
  opacity: 0;
  transition: opacity 0.37s;
}
.video-ctnr._show {
  opacity: 1;
}
.video-figure {
  width: 450px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

video.video {
  width: 320px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
  z-index: 2;
}

.group {
  width: 375px;
  flex-shrink: 0;
}

.red-dot {
  padding-left: 26px;
}

.red-dot::before {
  position: absolute;
  top: 14px;
  left: 0;
  content: '';
  width: 14px;
  height: 14px;
  background: #f23e3e;
  transform: rotate(80deg);
}

.img-ctnr {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 144px;
  min-width: calc(996px / 4);
}

#prod-1 {
  height: 47px;
  width: 200px;
}
#prod-2 {
  height: 115px;
  width: 110px;
}
#prod-3 {
  height: 52.6px;
  width: 200px;
}
#prod-4 {
  height: 116px;
  width: 176px;
}
#prod-5 {
  width: 200px;
  height: 76px;
}
#prod-6 {
  width: 219px;
  height: 60px;
}
#prod-7 {
  width: 175px;
  height: 70px;
}
#prod-8 {
  width: 211px;
  height: 62px;
}
#prod-9,
#prod-10 {
  width: 200px;
}
.img-ctnr .hover-reveal {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
  width: 294px;
  padding: 8px 12px;
  font-weight: bold;
  color: #fff;
  background-color: #283044;
  font-size: 12px;
  border-radius: 2px;
  box-shadow: 0 6px 26px rgba(0, 0, 0, 0.12);
}
.img-ctnr:hover .hover-reveal {
  opacity: 1;
}
.img-ctnr + .img-ctnr:before {
  position: absolute;
  left: -1px;
  top: 64px;
  content: '';
  height: 17px;
  width: 2px;
  background: #e6e9ee;
}

footer {
  margin-top: 104px;
  padding: 64px 135px;
}

.footer-content {
  max-width: 1170px;
  min-width: 825px;
  margin: 0 auto;
}

#qr-apple,
#qr-android {
  width: 108px;
  height: 108px;
}

#gh-bcz,
#gh-bczzp {
  position: absolute;
  opacity: 1;
  width: 190px;
  top: -210px;
  left: 50%;
  transform: translate(-50%, 32px);
  opacity: 0;
  pointer-events: none;
}

#gh-bcz._show,
#gh-bczzp._show {
  transform: translate(-50%, 0);
  opacity: 1;
}

/* /////////////////////////////////////////////
  4. Media Query
///////////////////////////////////////////// */

@media only screen and (max-width: 500px) {
  html {
    min-width: 375px;
  }
  .x2y {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .fw-wr {
    flex-direction: column-reverse;
  }
  #nav,
  #first-page {
    width: 100%;
    min-width: 315px;
  }
  .section > * {
    width: 100%;
    flex-wrap: wrap;
    min-width: auto;
  }
  .section,
  h2,
  h3 {
    min-width: 375px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .flexY {
    align-items: center;
  }
  #ss-1,
  .group.ml56,
  #ss-3 {
    margin-left: 0;
  }
  .img-ctnr {
    width: 50%;
  }
  .img-ctnr:nth-child(3):before {
    display: none;
  }
  .footer-content {
    min-width: auto;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .footer-content > .ml32 {
    margin-top: 46px;
    margin-left: 0;
  }
  .group {
    margin-top: 32px;
    width: 100%;
    max-width: 320px;
  }
  .video-ctnr {
    max-width: 320px;
    width: 100%;
  }
  #btn-android,
  .qr-ctnr_android {
    left: auto;
    right: 0;
  }
  #bg-blue,
  footer {
    padding-left: 30px;
    padding-right: 30px;
  }
  .img-ctnr:before {
    display: none;
  }
  /* 首屏 absolute 的按钮和图片布局缩放 */
  #illu-slogen {
    margin-top: 10.4vw;
    height: 32.7vw;
    margin-bottom: 32vw;
    width: 58.85vw;
  }
  #anim {
    width: 70vw;
    height: 75vw;
  }
  #screenshot-left {
    width: 10.67vw;
    height: 62.2vw;
    bottom: -6.5vw;
    left: 8vw;
  }
  #screenshot-left._show {
    transform: translate(-3.9vw, -6.5vw);
  }
  #screenshot-iphone {
    width: 48.7vw;
    height: 75vw;
    left: 10.6vw;
    bottom: -5.2vw;
  }
  #screenshot-iphone._show {
    transform: translate(0, -5.2vw);
  }
  #screenshot-right {
    height: 52vw;
    width: 11.46vw;
    bottom: -6.5vw;
    right: 7.55vw;
  }
  #screenshot-right._show {
    transform: translate(3.9vw, -6.5vw);
  }
  .width-10 {
    padding-left: 0;
    padding-right: 0;
  }
  #btn-apple,
  #btn-android {
    width: 22.4vw;
    height: 8.85vw;
    border-radius: 0.78vw;
    bottom: 8.33vw;
    padding: 0 2.6vw;
  }
  .qr-ctnr_apple,
  .qr-ctnr_android {
    width: 26.1vw;
    height: 28.8vw;
    bottom: 9.3vw;
    left: -1.4vw;
  }
  #company-banner {
    height: 320px;
    width: 100%;
    margin-top: 40px;
    border-radius: 0;
  }
  #cooporation-banner {
    height: 320px;
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
  #company-mask {
    right: -135px;
  }
  #government-banner {
    width: 100%;
    height: 195px;
    margin-top: 40px;
    border-radius: 0;
  }
  footer {
    margin-top: 0;
  }
}
